import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Preto
    },
    white: {
      main: "#ffffff", // Branco
    },
    secondary: {
      main: "#FFD700", // Amarelo ouro
    },
    background: {
      default: "#ffffff", // Fundo branco para um visual limpo e profissional
      paper: "#f5f5f5", // Fundo dos cards e áreas de destaque
    },
    text: {
      primary: "#000000", // Texto em preto para contraste
      secondary: "#FFD700", // Amarelo para elementos de destaque
      white: "#ffffff",
      blue: "#1976d2",
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 900,
      fontSize: "3rem",
      color: "#000000",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
    },
    h2: {
      fontWeight: 800,
      fontSize: "2.5rem",
      color: "#000000",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2rem",
      color: "#000000",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.8rem",
      color: "#FFD700",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
      color: "#000000",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#000000",
    },
    body2: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "#555555",
    },
    body3: {
      fontSize: "1.1rem",
      fontWeight: 500,
      color: "#555555",
      fontFamily: "roboto",
    },
    button: {
      fontWeight: 700,
      fontSize: "1rem",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          padding: "12px 30px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.08)",
            backgroundColor: "#FFD700", // Torna o botão amarelo ao passar o mouse
            color: "#000000", // Texto preto para contraste
          },
        },
        containedSecondary: {
          backgroundColor: "#FFD700",
          color: "#000000",
          "&:hover": {
            backgroundColor: "#FFC107",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          padding: 20,
          boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.15)",
          borderColor: "#FFD700",
          borderWidth: 1,
          borderStyle: "solid",
        },
      },
    },
  },
});

export default theme;
