import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  LinearProgress,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Send, ListAlt } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import { useEvent } from "../../../context/EventContext";
import api from "../../../api";

const AnswerViolationQuestions = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const { updateEvent } = useEvent();
  const [messages, setMessages] = useState([]);
  const [answerChoices, setAnswerChoices] = useState([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [totalQuestionCount, setTotalQuestionCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [questionLoaded, setQuestionLoaded] = useState(true);
  const [isAllAnswered, setIsAllAnswered] = useState(false);

  const progressValue =
    totalQuestionCount > 0
      ? (currentQuestionNumber / totalQuestionCount) * 100
      : 0;

  const fetchMessages = async () => {
    if (!identifier) return;
    setLoaded(false);
    try {
      const response = await api.get(`/chats/${identifier}`);
      const {
        messages,
        answer_choices,
        total_question_count,
        current_question_number,
        started,
      } = response.data;

      setMessages([...messages]);
      setAnswerChoices(answer_choices);
      setTotalQuestionCount(total_question_count);
      setCurrentQuestionNumber(current_question_number);
      setIsAllAnswered(current_question_number >= total_question_count);
      setLoaded(true);

      if (!started) {
        startConversation(identifier);
      }

      updateEvent(identifier, { status: "violation_informed" });
    } catch (error) {
      console.error("Erro ao buscar as mensagens:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [identifier]);

  const startConversation = async (identifier) => {
    try {
      const response = await api.patch(`/chats/${identifier}/start`);
      if (response.data.success) {
        setMessages(response.data.push_messages);
        setAnswerChoices(response.data.event_question_options);
        setTotalQuestionCount(response.data.total_question_count);
        setCurrentQuestionNumber(response.data.current_question_number);
        setIsAllAnswered(
          response.data.current_question_number ===
            response.data.total_question_count
        );
      }
    } catch (error) {
      console.error("Erro ao iniciar a conversa:", error);
    }
  };

  const handleAnswer = async (choice) => {
    setQuestionLoaded(false);
    try {
      const response = await api.post(`/chats/${identifier}/answer`, {
        option_id: choice.id,
      });

      if (response.data.success) {
        setMessages((prevMessages) => [
          ...prevMessages,
          ...response.data.push_messages,
        ]);
        setAnswerChoices(response.data.event_question_options);
        setTotalQuestionCount(response.data.total_question_count);
        setCurrentQuestionNumber(response.data.current_question_number);
        setIsAllAnswered(
          response.data.current_question_number >=
            response.data.total_question_count
        );
      }
    } catch (error) {
      console.error("Erro ao enviar a resposta:", error);
    } finally {
      setQuestionLoaded(true);
    }
  };

  const concludeEvent = () =>
    navigate(`/analyze-defense-arguments/${identifier}`);

  return (
    <Box
      id="chat-app"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.default",
        color: "text.primary",
        p: 2,
        background: "linear-gradient(to bottom, #f5f5f5, #e0e0e0)",
      }}
    >
      <Box
        id="app-container"
        sx={{
          flex: 1,
          minWidth: "90%",
          maxWidth: "90%",
          mx: "auto",
          overflow: "none",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          bgcolor: "#fff",
          p: 3,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            mb: 3,
            color: "primary.main",
          }}
        >
          Perguntas para sua Defesa
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 3,
            p: 2,
          }}
        >
          <ListAlt
            sx={{
              fontSize: 50,
              color: "primary.main",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 1,
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "text.primary",
                mb: 1,
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
              }}
            >
              Pergunta {currentQuestionNumber} de {totalQuestionCount}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": {
                  background: "linear-gradient(to right,rgb(228, 217, 13),rgb(225, 160, 21))",
                  borderRadius: 5,
                },
              }}
            />
          </Box>
        </Box>

        <Box
          id="app-message-list"
          sx={{
            flexGrow: 1,
            p: 2,
            minHeight: "150px",
          }}
        >
          {questionLoaded ? (
            <>
              {messages.length > 0 && (
                <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
                  {messages[messages.length - 1].content}
                </Typography>
              )}
              <Grid container spacing={2} direction="column">
                {answerChoices.map((choice) => (
                  <Grid item xs={12} key={choice.id}>
                    <Card
                      onClick={questionLoaded ? () => handleAnswer(choice) : null}
                      sx={{
                        cursor: questionLoaded ? "pointer" : "not-allowed",
                        opacity: questionLoaded ? 1 : 0.5,
                        backgroundColor: "#f9f9f9",
                        borderRadius: 0,
                        "&:hover": { backgroundColor: "#f0f0f0" },
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" textAlign="center">
                          {choice.content}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <CircularProgress color="primary" />
            </Box>
          )}
        </Box>

        <Grid container justifyContent="center" spacing={2} sx={{ mt: 4 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Send />}
              onClick={concludeEvent}
              disabled={!isAllAnswered}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AnswerViolationQuestions;
