import React, { createContext, useContext, useState, useEffect } from 'react';
import api, { setAuthToken } from '../api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthTokenState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const response = await api.post('/users/login', { email, password });
      const { token, user } = response.data;

      localStorage.setItem('authToken', token);
      setAuthTokenState(token);
      setUser(user);
      setError(null);

      setAuthToken(token);

      return response.data;
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setError(error.response?.data?.message || 'Erro ao fazer login');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (email, password) => {
    setLoading(true);
    try {
      const response = await api.post('/users', { user: { email, password } });
      const { token, user } = response.data;
      if (!token || !user) {
        throw new Error('Token ou usuário não retornado');
      }
      localStorage.setItem('authToken', token);
      setAuthTokenState(token);
      setUser(user);
      setAuthToken(token);
      setError(null);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar a conta:', error);
      setError(error.response?.data?.message || 'Erro ao criar a conta');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('eventData');

    setAuthTokenState(null);
    setUser(null);
    setAuthToken(null);
    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthTokenState(token);
      setAuthToken(token);
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, authToken, loading, error, login, signUp, logout }}>
      {loading ? <div>Carregando...</div> : children}
    </AuthContext.Provider>
  );
};
