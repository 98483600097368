import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { VerifiedUser, ReportProblem, Gavel } from "@mui/icons-material";

const InterestingFacts = () => {
  const facts = [
    {
      icon: <VerifiedUser sx={{ fontSize: 80, color: "#00953E" }} />,
      text: "Enquanto sua multa não é julgada nas três instâncias possíveis, você não precisa pagar o valor dela.",
    },
    {
      icon: <ReportProblem sx={{ fontSize: 80, color: "#2C2182" }} />,
      text: "Se você recorrer em todas as 3 instâncias e o processo demorar mais de 1 ano, você não receberá os pontos na CNH, mesmo que você perca o recurso.",
    },
    {
      icon: <Gavel sx={{ fontSize: 80, color: "#FFCC00" }} />,
      text: "Utilizar argumentos jurídicos sólidos aumenta em até 70% as chances de sucesso.",
    },
  ];

  return (
    <Box
      sx={{
        mt: 8,
        py: 6,
        px: 3,
        color: "#fff",
        position: "relative",
        overflow: "hidden",
        borderRadius: 2,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: "url('/videos/transito2.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0,
          filter: "brightness(0.5)",
        },
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        gutterBottom
        sx={{ position: "relative", marginBottom: "3%", zIndex: 1 }}
      >
        Fatos Interessantes sobre Recursos de Multas no Brasil
      </Typography>
      <Grid container spacing={3} sx={{ position: "relative", zIndex: 1 }}>
        {facts.map((fact, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                textAlign: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: 2,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {fact.icon}
              <Typography variant="body1" color="text.primary" sx={{ mt: 2 }}>
                {fact.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InterestingFacts;
