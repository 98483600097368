import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Box,
  Autocomplete,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Send, Search } from "@mui/icons-material";
import { useFormik } from "formik";
import { useEvent } from "../../../context/EventContext";
import api from "../../../api";
import DetransWithLinks from "../../../components/modals/DetransWithLinks";

const DefineViolation = () => {
  const { event, eventIdentifier, updateEvent, loading } = useEvent();
  const [flows, setFlows] = useState([]);
  const [filteredFlowOptions, setFilteredFlowOptions] = useState([]);
  const [selectedFlowId, setSelectedFlowId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  // Abre o modal
  const handleModalOpen = () => setModalOpen(true);
  // Fecha o modal
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await api.get("/flows");
        setFlows(response.data.flows);
      } catch (error) {
        console.error("Erro ao buscar os flows:", error.message);
      }
    };
    fetchFlows();

    if (event && event.status !== "process_started") {
      updateEvent(eventIdentifier, { status: "process_started" });
    }
  }, [event, eventIdentifier, updateEvent]);

  const selectFlow = (flowId) => setSelectedFlowId(flowId);

  const formik = useFormik({
    initialValues: { eventCode: "" },
    onSubmit: async () => {
      if (selectedFlowId) {
        setIsLoading(true);
        try {
          if (eventIdentifier) {
            await updateEvent(eventIdentifier, { flow_id: selectedFlowId });
            navigate(`/answer-violation-questions/${eventIdentifier}`);
          } else {
            console.error("Erro: Identificador do evento não encontrado.");
          }
        } catch (error) {
          console.error("Erro ao atualizar o evento com flow_id:", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.eventCode.length === 4) {
      const filtered = flows.filter((flow) =>
        flow.law.startsWith(formik.values.eventCode)
      );
      setFilteredFlowOptions(filtered);
      if (filtered.length === 1) setSelectedFlowId(filtered[0].id);
    } else {
      setFilteredFlowOptions([]);
      setSelectedFlowId("");
    }
  }, [formik.values.eventCode, flows]);

  return (
    <Box
      sx={{
        py: 6,
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        background: "linear-gradient(to bottom, #f5f5f5, #e0e0e0)",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          mx: "auto",
          px: 4,
          py: 5,
          mt: { xs: 4, sm: 6 },
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            {/* Ícone Centralizado */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Search sx={{ fontSize: 50, color: "#3f51b5" }} />
            </Box>

            {/* Título */}
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "center", mb: 3 }}
            >
              Digite o código da infração ou pesquise pela descrição
            </Typography>

            {/* Código da Infração */}
            <TextField
              name="eventCode"
              label="Código da Infração"
              variant="outlined"
              fullWidth
              value={formik.values.eventCode}
              onChange={formik.handleChange}
              inputProps={{ maxLength: 4 }}
              sx={{ mb: 3 }}
            />

            {/* Feedback e Opções */}
            {filteredFlowOptions.length === 0 &&
              formik.values.eventCode.length === 4 && (
                <Typography color="error" sx={{ mb: 2 }}>
                  Nenhuma infração encontrada.
                </Typography>
              )}
            {filteredFlowOptions.length > 0 && (
              <Box>
                {filteredFlowOptions.map((option) => (
                  <Card
                    key={option.id}
                    onClick={() => selectFlow(option.id)}
                    sx={{
                      mb: 2,
                      cursor: "pointer",
                      backgroundColor:
                        selectedFlowId === option.id ? "#f0f0f0" : "white",
                      "&:hover": { backgroundColor: "#f5f5f5" },
                    }}
                  >
                    <CardContent>{option.description}</CardContent>
                  </Card>
                ))}
              </Box>
            )}

            {/* Pesquisa de Infração */}
            <Autocomplete
              options={flows}
              getOptionLabel={(option) => option.description}
              onChange={(event, newValue) => selectFlow(newValue?.id || "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pesquisar Infração"
                  variant="outlined"
                  fullWidth
                />
              )}
              sx={{ mb: 4 }}
            />

            {/* Botão para Abrir o Modal */}
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                mb: 3,
              }}
            >
              Precisa de ajuda para achar?{" "}
              <Button
                onClick={handleModalOpen}
                variant="text"
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  textTransform: "none",
                  padding: 0,
                  minWidth: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Clique aqui
              </Button>
            </Typography>

            {/* Botão de Submissão */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!selectedFlowId || loading}
              endIcon={isLoading ? <CircularProgress size={20} /> : <Send />}
              fullWidth
            >
              {isLoading ? "Carregando..." : "Continuar"}
            </Button>
          </form>
        )}
      </Box>

      {/* Modal de Links para os Detrans */}
      <DetransWithLinks open={modalOpen} onClose={handleModalClose} />
    </Box>
  );
};

export default DefineViolation;