import React, { createContext, useContext, useState } from 'react';
import api from '../api';

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState(() => {
    const storedEvent = localStorage.getItem('eventData');
    return storedEvent ? JSON.parse(storedEvent) : null;
  });

  const [eventIdentifier, setEventIdentifier] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const persistEventInLocalStorage = (newEvent) => {
    const mergedEvent = { ...event, ...newEvent };
    setEvent(mergedEvent);
    localStorage.setItem('eventData', JSON.stringify(mergedEvent));
  };

  const createEvent = async () => {
    setLoading(true);
    try {
      const response = await api.post('/events', {
        event: { status: 'process_started' },
      });
      setEventIdentifier(response.data.event.identifier);

      persistEventInLocalStorage(response.data.event);
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao criar o evento:', error);
      setErrorMessage(error.response?.data?.message || 'Erro ao criar o evento');
    } finally {
      setLoading(false);
    }
  };

  const updateEvent = async (identifier, data) => {
    setLoading(true);
    try {
      const response = await api.patch(`/events/${identifier}`, {
        event: data,
      });
      persistEventInLocalStorage(response.data.event);
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao atualizar o evento:', error);
      setErrorMessage(error.response?.data?.message || 'Erro ao atualizar o evento');
    } finally {
      setLoading(false);
    }
  };

  const fetchEvent = async (identifier, action = '') => {
    setLoading(true);
    try {
      const endpoint = action
        ? `/events/${identifier}/${action}`
        : `/events/${identifier}`;
      const response = await api.get(endpoint);

      if (response.data.event) {
        persistEventInLocalStorage(response.data.event);
      }
      else if (response.data.success && response.data.invoice) {
      } else {
        persistEventInLocalStorage(response.data);
      }

      setErrorMessage('');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar o evento:', error);
      setErrorMessage(error.response?.data?.message || 'Erro ao buscar o evento');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchEventArguments = async (identifier) => {
    setLoading(true);
    try {
      const response = await api.get(`/events/${identifier}/arguments`);
      persistEventInLocalStorage({
        arguments_user_description: response.data.arguments,
        no_legal_arguments: response.data.no_legal_arguments,
      });
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao buscar os argumentos do evento:', error);
      setErrorMessage(error.response?.data?.message || 'Erro ao buscar os argumentos do evento');
    } finally {
      setLoading(false);
    }
  };

  const associateEventWithUser = async (identifier) => {
    setLoading(true);
    try {
      await api.post(`/events/${identifier}/assign_to_user`);
      setErrorMessage('');
    } catch (error) {
      console.error('Erro ao associar o evento ao usuário:', error);
      setErrorMessage(error.response?.data?.message || 'Erro ao associar o evento ao usuário');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const clearEvent = () => {
    setEvent(null);
    localStorage.removeItem('eventData');
  };

  return (
    <EventContext.Provider
      value={{
        event,
        eventIdentifier,
        loading,
        errorMessage,
        createEvent,
        updateEvent,
        fetchEvent,
        fetchEventArguments,
        associateEventWithUser,
        clearEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => useContext(EventContext);
