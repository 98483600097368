import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  FactCheck,
  AttachMoney,
  SupportAgent,
  Gavel,
  DirectionsCar,
  DoneAll,
} from "@mui/icons-material";
import { useEvent } from "../context/EventContext";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const { createEvent } = useEvent();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);

  const steps = [
    "Digite o código da infração ou selecione o tipo",
    "Responda às perguntas para a sua defesa",
    "Consulte os argumentos jurídicos gerados gratuitamente",
    "Realize o cadastro, faça o pagamento, e obtenha o seu recurso pronto para ser protocolado",
  ];

  const handleRecorrerAgora = async () => {
    setLoading(true);
    setError(null);
    try {
      await createEvent();
      navigate("/define-violation");
    } catch (err) {
      setError(
        "Não foi possível iniciar o processo. Tente novamente mais tarde."
      );
      console.error("Erro ao criar evento:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: "background.default", minHeight: "100vh" }}>
      {/* Banner com Vídeo */}
      <Box
        sx={{
          position: "relative",
          color: "#fff",
          textAlign: "center",
          height: { xs: "80vh", sm: "75vh" },
          overflow: "hidden",
        }}
      >
        <video
          ref={videoRef}
          src="/videos/animation4.mp4"
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            backgroundColor: "rgba(226, 224, 212, 0.8)",
            padding: { xs: 3, sm: 5 },
            borderRadius: "8px",
            width: { xs: "90%", sm: "80%" },
            maxHeight: { xs: "60vh", sm: "none" },
            overflowY: { xs: "auto", sm: "visible" },
          }}
        >
          <Typography
            variant="h2"
            align="center"
            sx={{
              color: "#000",
              mb: { xs: 2, sm: 4 },
              fontWeight: "bold",
            }}
          >
            Recebeu uma multa de trânsito?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                icon: <FactCheck color="primary" sx={{ fontSize: 50 }} />,
                title: "Recurso de Multa",
                description:
                  "Direito legal que permite contestar penalidades de trânsito injustas.",
              },
              {
                icon: <AttachMoney color="primary" sx={{ fontSize: 50 }} />,
                title: "Direito ao Reembolso",
                description:
                  "Recorrer permite adiar o pagamento e, se aceito, recuperar o valor pago.",
              },
              {
                icon: <SupportAgent color="primary" sx={{ fontSize: 50 }} />,
                title: "Recorra sem Advogado",
                description:
                  "Nossa plataforma ajuda você a criar recursos de multa com facilidade.",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    textAlign: "center",
                    padding: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    borderRadius: 2,
                  }}
                >
                  {item.icon}
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.primary",
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}

            <Box sx={{ mt: 6 }}>
              <Typography variant="h5" sx={{ mb: 4 }}>
                Gere seu recurso em poucos minutos.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleRecorrerAgora}
                disabled={loading}
                sx={{ px: 6 }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Solicitar meu recurso"
                )}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>

      {/* Nova Sessão: Como Funciona o Recurso Contra Multas */}
      <Box
        sx={{
          position: "relative",
          backgroundImage: "url(/videos/transito.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          py: { xs: 6, sm: 10 },
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.92)",
            zIndex: 1,
          }}
        />
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: 4,
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.8)",
            }}
          >
            Como funciona o recurso contra multas de trânsito?
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                icon: (
                  <DirectionsCar sx={{ fontSize: 100, color: "#EC781C" }} />
                ),
                title: "Defesa Prévia",
                description:
                  "Essa é a primeira oportunidade de apresentar argumentos e provas que comprovem a irregularidade da infração. Caso a Defesa Prévia seja aceita, a multa será cancelada.",
              },
              {
                icon: <Gavel sx={{ fontSize: 100, color: "#F7F700" }} />,
                title: "Recurso em 1ª Instância (JARI)",
                description:
                  "Se a Defesa Prévia for negada, você pode recorrer à Junta Administrativa de Recursos de Infrações (JARI). Aqui, serão avaliados os documentos apresentados e a justificativa para contestar a multa.",
              },
              {
                icon: <DoneAll sx={{ fontSize: 100, color: "#00C900" }} />,
                title: "Recurso em 2ª Instância (CETRAN)",
                description:
                  "Se o recurso na JARI for negado, é possível recorrer ao Conselho Estadual de Trânsito (CETRAN). Esta é a última instância administrativa para contestar a penalidade.",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    textAlign: "center",
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {item.icon}
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontWeight: "bold",
                      color: "#ffc107",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body3" sx={{ color: "#f5f5f5" }}>
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 6 }}>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Clique abaixo para começar e gere seu recurso em poucos minutos.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleRecorrerAgora}
              disabled={loading}
              sx={{ px: 6 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Começar Agora"
              )}
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Nova Sessão: Motivos para Utilizar Nossos Serviços */}
      <Box
        sx={{
          backgroundColor: "#fff",
          py: { xs: 6, sm: 10 },
        }}
      >
        <Container>
          <Grid container spacing={4} alignItems="center">
            {/* Vídeo */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: 2,
                  overflow: "hidden", // Para garantir que o vídeo tenha bordas arredondadas
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <video
                  src="/videos/animation7.mp4" // Substitua pelo caminho do seu vídeo
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>

            {/* Texto */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{
                  mb: 4,
                  fontWeight: "bold",
                  color: "text.primary",
                }}
              >
                Por que utilizar nossos serviços?
              </Typography>
              <Grid container spacing={2}>
                {[
                  {
                    title: "Sem Complicações, Apenas Resultados",
                    description:
                      "Esqueça a burocracia! Nossa plataforma facilita o processo de recorrer, oferecendo petições completas e prontas para protocolo, elaboradas por advogados experientes.",
                  },
                  {
                    title: "Economia de Tempo e Dinheiro",
                    description:
                      "Não pague multas indevidas! Nossos recursos aumentam suas chances de sucesso, poupando seu bolso e eliminando a necessidade de gastos desnecessários.",
                  },
                  {
                    title: "Atendimento Rápido e Prático",
                    description:
                      "Receba sua petição em tempo recorde! Nosso processo é ágil e totalmente online, para que você recorra de forma rápida e eficiente.",
                  },
                  {
                    title: "Garantia de Qualidade e Profissionalismo",
                    description:
                      "Cada recurso é elaborado com o mais alto padrão jurídico, utilizando argumentos sólidos e as melhores práticas legais para defender seus direitos.",
                  },
                ].map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        mb: 1,
                        color: "#EC781C",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "text.primary" }}>
                      {item.description}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Gráfico Circular */}
      <Box
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.92)",
          color: "secondary.main",
          py: 6,
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" marginBottom="10%" gutterBottom>
            Como Gerar o Meu Recurso?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={(index + 1) * (100 / steps.length)}
                    size={80}
                    thickness={5}
                    sx={{ color: "#58E22E" }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Passo {index + 1}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.white",
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                    }}
                  >
                    {step}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mt: 6 }}>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Clique abaixo para começar e gere seu recurso em poucos minutos.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleRecorrerAgora}
              disabled={loading}
              sx={{ px: 6 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Começar Agora"
              )}
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
