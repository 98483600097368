import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import { Balance } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import { useEvent } from "../../../context/EventContext";
import InterestingFacts from "./InterestingFacts";

const ArgumentsList = ({ argumentsList }) => (
  <Grid container spacing={3} justifyContent="center">
    {argumentsList.map((argument, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            textAlign: "center",
            "&:hover": { transform: "scale(1.05)" },
          }}
        >
          <Balance sx={{ fontSize: 50, color: "primary.main", mb: 2 }} />
          <Typography variant="body1">{argument}</Typography>
        </Paper>
      </Grid>
    ))}
  </Grid>
);

const AnalyzeDefenseArguments = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();

  const {
    event,
    loading,
    fetchEvent,
    updateEvent,
    fetchEventArguments,
  } = useEvent();

  const [statusUpdated, setStatusUpdated] = useState(false);
  const [argumentsLoaded, setArgumentsLoaded] = useState(false);

  const [error, setError] = useState(null);

  const [storedArgumentsList, setStoredArgumentsList] = useState([]);

  useEffect(() => {
    const savedArguments = localStorage.getItem(`argumentsList_${identifier}`);
    if (savedArguments) {
      setStoredArgumentsList(JSON.parse(savedArguments));
    }
  }, [identifier]);

  useEffect(() => {
    if (Array.isArray(event?.arguments_user_description)) {
      localStorage.setItem(
        `argumentsList_${identifier}`,
        JSON.stringify(event.arguments_user_description)
      );
      setStoredArgumentsList(event.arguments_user_description);
    }
  }, [event?.arguments_user_description, identifier]);

  useEffect(() => {
    const getEvent = async () => {
      if (event) return;
      try {
        await fetchEvent(identifier);
      } catch (err) {
        setError("Erro ao buscar o evento.");
      }
    };

    getEvent();
  }, [identifier, event, fetchEvent]);

  useEffect(() => {
    const updateEventStatus = async () => {
      if (event && !statusUpdated && event.status !== "questions_completed") {
        try {
          await updateEvent(identifier, { status: "questions_completed" });
          setStatusUpdated(true);
        } catch (err) {
          setError("Erro ao atualizar o status do evento.");
        }
      }
    };

    updateEventStatus();
  }, [identifier, event, statusUpdated, updateEvent]);

  useEffect(() => {
    const loadArguments = async () => {
      if (event && statusUpdated && !argumentsLoaded) {
        try {
          await fetchEventArguments(identifier);
          setArgumentsLoaded(true);
        } catch (err) {
          setError("Erro ao carregar os argumentos do evento.");
        }
      }
    };

    loadArguments();
  }, [identifier, event, statusUpdated, argumentsLoaded, fetchEventArguments]);

  const handleGenerateResource = () => {
    navigate(`/login-or-signup/${identifier}`);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" sx={{ py: 6, px: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ py: 6, px: 3 }}>
        <ArgumentsList argumentsList={storedArgumentsList} />

        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            alignItems="center"
            justifyContent="center"
            onClick={handleGenerateResource}
            sx={{ mt: 4 }}
          >
            GERAR RECURSO
          </Button>
        </Box>
      </Box>
      <Box>
        <InterestingFacts />
      </Box>
    </Box>
  );
};

export default AnalyzeDefenseArguments;
