import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
  Link,
} from '@mui/material';
import { Close, Launch } from '@mui/icons-material';

const detransLinks = [
  { state: 'Acre', link: 'http://www.detran.ac.gov.br/' },
  { state: 'Alagoas', link: 'http://www.detran.al.gov.br/' },
  { state: 'Amapá', link: 'http://www.detran.ap.gov.br/' },
  { state: 'Amazonas', link: 'http://www.detran.am.gov.br/' },
  { state: 'Bahia', link: 'http://www.detran.ba.gov.br/' },
  { state: 'Ceará', link: 'http://www.detran.ce.gov.br/' },
  { state: 'Distrito Federal', link: 'http://www.detran.df.gov.br/' },
  { state: 'Espírito Santo', link: 'http://www.detran.es.gov.br/' },
  { state: 'Goiás', link: 'http://www.detran.go.gov.br/' },
  { state: 'Maranhão', link: 'http://www.detran.ma.gov.br/' },
  { state: 'Mato Grosso', link: 'http://www.detran.mt.gov.br/' },
  { state: 'Mato Grosso do Sul', link: 'http://www.detran.ms.gov.br/' },
  { state: 'Minas Gerais', link: 'https://www.detran.mg.gov.br/' },
  { state: 'Pará', link: 'http://www.detran.pa.gov.br/' },
  { state: 'Paraíba', link: 'http://www.detran.pb.gov.br/' },
  { state: 'Paraná', link: 'http://www.detran.pr.gov.br/' },
  { state: 'Pernambuco', link: 'http://www.detran.pe.gov.br/' },
  { state: 'Piauí', link: 'http://www.detran.pi.gov.br/' },
  { state: 'Rio de Janeiro', link: 'http://www.detran.rj.gov.br/' },
  { state: 'Rio Grande do Norte', link: 'http://www.detran.rn.gov.br/' },
  { state: 'Rio Grande do Sul', link: 'http://www.detran.rs.gov.br/' },
  { state: 'Rondônia', link: 'http://www.detran.ro.gov.br/' },
  { state: 'Roraima', link: 'http://www.detran.rr.gov.br/' },
  { state: 'Santa Catarina', link: 'http://www.detran.sc.gov.br/' },
  { state: 'São Paulo', link: 'http://www.detran.sp.gov.br/' },
  { state: 'Sergipe', link: 'http://www.detran.se.gov.br/' },
  { state: 'Tocantins', link: 'http://www.detran.to.gov.br/' },
];

const DetransWithLinks = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>
      Encontre o Código da Multa no Detran
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers>
      <Typography paragraph>
        Para fazer sua petição, você precisará do código da multa. Basta acessar
        o site do Detran do seu estado, procurar pela seção de multas e
        localizar o código correspondente.
      </Typography>
      <List>
        {detransLinks.map(({ state, link }) => (
          <ListItem key={state}>
            <ListItemText primary={state} />
            <ListItemIcon>
              <Link href={link} target="_blank" rel="noopener noreferrer">
                <IconButton aria-label={`Acessar site do Detran de ${state}`}>
                  <Launch />
                </IconButton>
              </Link>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Fechar
      </Button>
    </DialogActions>
  </Dialog>
);

export default DetransWithLinks;
