import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, CircularProgress } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../../api';

const DownloadPetition = () => {
  const { identifier } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDownload = () => {
    const url = `${api.defaults.baseURL}/events/${identifier}/download_document?download=true`;
    window.open(url, '_blank');
  };  

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await api.get(`/events/${identifier}`);
        setEvent(response.data.event);
        setLoading(false);

        if (response.data.event.status !== 'petition_completed') {
          await api.patch(`/events/${identifier}`, {
            event: { status: 'petition_completed' },
          });
        }

      } catch (error) {
        console.error('Erro ao buscar os detalhes do evento:', error);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [identifier]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!event) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <Typography variant="h6">Evento não encontrado</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '900px', mx: 'auto' }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Sua petição está pronta!
      </Typography>

      {/* Botão para baixar a petição */}
      <Button
        variant="contained"
        color="success"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handleDownload}
      >
        Baixar Petição
      </Button>

      {/* Botão para atualizar as informações */}
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mb: 2 }}
        onClick={() => navigate(`/fill-personal-vehicle-info/${identifier}`)}
      >
        Atualizar Informações
      </Button>

      <Button
        variant="contained"
        color="terciary"
        fullWidth
        sx={{ mb: 2 }}
        onClick={() => navigate('/user-petitions')}
      >
        Minhas Petições
      </Button>

      <Typography variant="body1" sx={{ mb: 4 }}>
        Você já pode entrar com seu recurso de multa utilizando a petição gerada.
      </Typography>

      {/* Informações adicionais */}
      <Paper sx={{ p: 3, mb: 4, bgcolor: 'info.main', color: 'white' }}>
        <Box display="flex" alignItems="center">
          <InfoOutlined sx={{ mr: 1 }} />
          <Typography variant="body1">
            Para o devido processamento de sua defesa, leve a petição gerada para o órgão informado em sua multa,
            presencialmente ou pelos correios.
          </Typography>
        </Box>
      </Paper>

      <Paper sx={{ p: 3, bgcolor: 'info.light', mb: 4 }}>
        <Box display="flex" alignItems="center">
          <InfoOutlined sx={{ mr: 1 }} />
          <Typography variant="body1">
            Confira seus dados pessoais e dados do veículo na petição baixada. Se houver algum erro, por favor, atualize
            as informações clicando no botão "Atualizar Informações" acima e então volte para baixar a petição
            atualizada.
          </Typography>
        </Box>
      </Paper>

      {/* Botão para voltar */}
      <Button variant="contained" color="primary" fullWidth onClick={() => navigate('/')}>
        Fazer nova petição
      </Button>
    </Box>
  );
};

export default DownloadPetition;
