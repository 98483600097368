import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ContentCopy, CheckCircle, Refresh, Send } from "@mui/icons-material";
import { useEvent } from "../../../context/EventContext";
import { useAuth } from "../../../context/AuthContext";

const CompletePayment = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();

  const { event, updateEvent, fetchEvent, loading } = useEvent();
  const { authToken, loading: authLoading } = useAuth();

  const [hasGeneratedInvoice, setHasGeneratedInvoice] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState({
    type: "",
    message: "",
  });
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);

  useEffect(() => {
    if (!authToken && !authLoading) {
      navigate("/login");
    }
  }, [authToken, authLoading, navigate]);

  useEffect(() => {
    const generateInvoiceIfNeeded = async () => {
      try {
        const fetchedEvent = await fetchEvent(identifier);
        if (
          fetchedEvent.status !== "account_registered" &&
          !hasGeneratedInvoice
        ) {
          await fetchEvent(identifier, "generate_invoice");
          await updateEvent(identifier, { status: "account_registered" });
          setHasGeneratedInvoice(true);
        }
      } catch (error) {
        console.error("Erro ao gerar a fatura ou atualizar o status:", error);
      }
    };

    if (authToken && !loading && !hasGeneratedInvoice) {
      generateInvoiceIfNeeded();
    }
  }, [
    authToken,
    loading,
    hasGeneratedInvoice,
    fetchEvent,
    updateEvent,
    identifier,
  ]);

  const checkPaymentStatus = async () => {
    setCheckingPayment(true);
    setFeedbackMessage({ type: "", message: "" });

    try {
      const response = await fetchEvent(identifier, "check_payment_status");
      if (response.status === "paid") {
        setIsPaymentConfirmed(true);
      } else {
        setIsPaymentConfirmed(false);
      }
      setDialogOpen(true);
    } catch (error) {
      setIsPaymentConfirmed(false);
      console.error("Erro ao verificar o status do pagamento:", error);
    } finally {
      setCheckingPayment(false);
    }
  };

  const copyToClipboard = () => {
    if (event?.iugu_qrcode_text) {
      navigator.clipboard.writeText(event.iugu_qrcode_text);
      setFeedbackMessage({
        type: "success",
        message: "Código Pix copiado com sucesso!",
      });
    }
  };

  const handleProceed = () => {
    navigate(`/fill-personal-vehicle-info/${identifier}`);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  if (loading || authLoading || !event) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        py: 6,
        px: 3,
        background: "linear-gradient(to bottom, #f5f5f5, #e0e0e0)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          p: 4,
          backgroundColor: "#fff",
          borderRadius: 3,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom color="primary">
          Fatura de Pagamento via Pix
        </Typography>
        <Typography variant="body1" paragraph>
          ✔ Garanta seu recurso por apenas <strong>R$ 25,00</strong>.
        </Typography>
        <Typography variant="body1" paragraph>
          {" "}
          ✔ Muitos motoristas já economizaram até <strong> 80%</strong>{" "}
          contestando multas. Invista em sua defesa e evite perder pontos.
        </Typography>

        {/* QR Code */}
        <Box sx={{ my: 4 }}>
          <Typography variant="body1" gutterBottom>
            Escaneie o QR Code ou copie o código abaixo:
          </Typography>
          {event.iugu_qrcode && (
            <img
              src={event.iugu_qrcode}
              alt="QR Code para pagamento via Pix"
              style={{ maxWidth: "200px", margin: "20px auto" }}
            />
          )}
          <TextField
            fullWidth
            value={event.iugu_qrcode_text || ""}
            InputProps={{ readOnly: true }}
            sx={{ mb: 3 }}
          />
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<ContentCopy />}
                onClick={copyToClipboard}
              >
                Copiar Código Pix
              </Button>
            </Grid>
            <Grid item>
              <Grid item>
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<Refresh />}
                  onClick={checkPaymentStatus}
                  disabled={checkingPayment}
                >
                  {checkingPayment ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Verificar Pagamento"
                  )}
                </Button>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    display: "block",
                    color: "text.primary",
                    fontStyle: "italic",
                  }}
                >
                  Clique para verificar se o pagamento via Pix já foi processado
                  aṕos o pagamento ser realizado.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Feedback */}
        {feedbackMessage.message && (
          <Alert severity={feedbackMessage.type} sx={{ mt: 2 }}>
            {feedbackMessage.message}
          </Alert>
        )}

        <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
          {/* Título do Modal */}
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: isPaymentConfirmed ? "success.main" : "error.main", // Verde para sucesso, vermelho para pendente
            }}
          >
            {isPaymentConfirmed
              ? "Pagamento Confirmado!"
              : "Pagamento Pendente"}
          </DialogTitle>

          {/* Texto do Conteúdo */}
          <DialogContent>
            <DialogContentText
              sx={{
                textAlign: "center",
                mb: 3,
                color: "text.primary", // Pode substituir por uma cor personalizada, como 'primary.main'
              }}
            >
              {isPaymentConfirmed
                ? "Seu pagamento foi confirmado com sucesso. Clique no botão abaixo para prosseguir."
                : "O pagamento ainda não foi confirmado. Verifique na sua conta bancária se o pagamento foi processado."}
            </DialogContentText>

            {/* Botão de Prosseguir */}
            {isPaymentConfirmed && (
              <Box textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProceed}
                  endIcon={<Send />}
                  startIcon={<CheckCircle />}
                >
                  Prosseguir
                </Button>
              </Box>
            )}
          </DialogContent>

          {/* Botão de Fechar para Pagamento Pendente */}
          {!isPaymentConfirmed && (
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                onClick={closeDialog}
                variant="outlined"
                sx={{
                  color: "error.main", // Cor personalizada no botão
                  borderColor: "error.main",
                  "&:hover": {
                    backgroundColor: "error.light",
                  },
                }}
              >
                Fechar
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};

export default CompletePayment;
