import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useEvent } from "../../../context/EventContext";
import { useAuth } from "../../../context/AuthContext";

const LoginOrSignUp = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const {
    fetchEvent,
    updateEvent,
    associateEventWithUser,
    event,
    loading: eventLoading,
  } = useEvent();
  const { login, signUp, loading: authLoading } = useAuth();

  const [view, setView] = useState("signUp");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const updateEventStatus = async () => {
      const fetchedEvent = await fetchEvent(identifier);
      if (fetchedEvent && fetchedEvent.status !== "arguments_reviewed") {
        await updateEvent(identifier, { status: "arguments_reviewed" });
      }
    };
    if (!eventLoading && event?.status !== "arguments_reviewed") {
      updateEventStatus();
    }
  }, [identifier, fetchEvent, updateEvent, eventLoading, event?.status]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const response =
        view === "signUp"
          ? await signUp(formData.email, formData.password)
          : await login(formData.email, formData.password);

      if (response.token) {
        await associateEventWithUser(identifier, response.token);
        navigate(`/complete-payment/${identifier}`);
      } else {
        throw new Error("Token não encontrado na resposta");
      }
    } catch (error) {
      console.error(
        `Erro ao ${view === "signUp" ? "registrar" : "logar"}:`,
        error
      );
      setErrorMessage(
        `Erro ao ${view === "signUp" ? "registrar" : "logar"}. Tente novamente.`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(to bottom, #f5f5f5, #e0e0e0)",
        p: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          width: "100%",
          backgroundColor: "#fff",
          p: 4,
          borderRadius: 2,
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: "primary.main", mb: 3 }}
        >
          {view === "signUp" ? "Crie sua conta" : "Faça Login"}
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {errorMessage}
          </Alert>
        )}

        <TextField
          label="E-mail"
          name="email"
          variant="outlined"
          fullWidth
          type="email"
          value={formData.email}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Senha"
          name="password"
          variant="outlined"
          fullWidth
          type="password"
          value={formData.password}
          onChange={handleChange}
          sx={{ mb: 3 }}
        />
        <Button
          variant="contained"
          color={view === "signUp" ? "secondary" : "primary"}
          onClick={handleSubmit}
          disabled={loading || authLoading}
          fullWidth
          sx={{
            py: 1.5,
            fontSize: "1rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          {loading || authLoading ? (
            <CircularProgress size={24} />
          ) : view === "signUp" ? (
            "Cadastrar"
          ) : (
            "Logar"
          )}
        </Button>

        <Typography
          variant="body2"
          sx={{ mt: 3, color: "text.blue", cursor: "pointer" }}
          onClick={() => setView(view === "signUp" ? "login" : "signUp")}
        >
          {view === "signUp"
            ? "Já tem uma conta? Faça login"
            : "Não tem uma conta? Cadastre-se"}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginOrSignUp;
